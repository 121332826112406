.email-config-container {
    font-family: Arial, sans-serif;
    margin: 20px;
    max-width: 600px;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  h2 {
    color: #0056b3;
  }
  
  .details {
    margin-bottom: 20px;
  }
  
  .details p {
    line-height: 1.6;
  }
  
  .details strong {
    display: inline-block;
    width: 150px;
  }
  
  .footer {
    font-size: 0.9em;
    color: #777;
    border-top: 1px solid #ddd;
    padding-top: 10px;
    text-align: center;
  }
  