.product__container.active{
    .drive-icon>path {
        transition: .3s;
        stroke: var(--F9F7F7);
    }

    .drive-icon>path:nth-child(3) {
        transition: .3s;
        stroke: transparent;
        fill: var(--F9F7F7);
    }

    .password-icon {
        transition: .3s;
        fill: var(--112D4E);
    }
}

.product__container.active{
    background-color: var(--112D4E);
}

.logo__icon {
    >path {
        transition: .3s;
        stroke: var(--F9F7F7);
    }

    >path:nth-child(3) {
        transition: .3s;
        stroke: transparent;
        fill: var(--F9F7F7);
    }
}