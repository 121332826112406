@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

div {
    cursor: url('./icons/cursorIcons/curor-arrow.png'), auto;
}

#root,
html,
body {
    width: 100%;
    height: 100vh;
    scroll-behavior: smooth;
}

:root {
    --112D4E: #112D4E;
    --3F72AF: #3F72AF;
    --86BCFC: #86BCFC;
    --DBE2EF: #DBE2EF;
    --F9F7F7: #F9F7F7;
}

::selection {
    color: var(--F9F7F7);
    background-color: var(--3F72AF);
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--112D4E);
    border-radius: 3px;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
span {
    cursor: url('./icons/cursorIcons/cursor-text.png'), auto;
}

.text-112D4E {
    color: var(--112D4E);
}

.bg-112D4E {
    background-color: var(--112D4E);
}

.text-3F72AF {
    color: var(--3F72AF);
}

.bg-3F72AF {
    background-color: var(--3F72AF);
}

.text-86BCFC {
    color: var(--86BCFC);
}

.bg-86BCFC {
    background-color: var(--86BCFC);
}

.text-DBE2EF {
    color: var(--DBE2EF);
}

.bg-DBE2EF {
    background-color: var(--DBE2EF);
}

.text-F9F7F7 {
    color: var(--F9F7F7);
}

.bg-F9F7F7 {
    background-color: var(--F9F7F7);
}

.cursor-pointer {
    cursor: url('./icons/cursorIcons/curor-pointer.png'), auto;
}

.cursor-text {
    cursor: url('./icons/cursorIcons/cursor-text.png'), auto;
}

.cursor-arrow {
    cursor: url('./icons/cursorIcons/curor-arrow.png'), auto;
}

.shadow {
    box-shadow: 0px 4px 30px 0px rgba(17, 45, 78, 0.10);
}

.openProfileModal {
    animation: openProfileModal 0.5s ease-in-out forwards;
}

.modal-shadow {
    box-shadow: 0 35px 60px 1005px #0A264760;
}

.forms-back {
    background-image: url('../public/formsBack.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.vibrate-anime {
    animation: vibrate 0.5s linear infinite both;
}

@keyframes vibrate {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-2px, 2px);
    }

    40% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(2px, 2px);
    }

    80% {
        transform: translate(2px, -2px);
    }

    100% {
        transform: translate(0);
    }
}

@keyframes openProfileModal {
    0% {
        opacity: 0;
        max-height: 0;
    }

    100% {
        opacity: 1;
        max-height: 800px;
    }
}

.closeProfileModal {
    animation: closeProfileModal 0.5s ease-in-out forwards;
}

@keyframes closeProfileModal {
    0% {
        opacity: 1;
        max-height: 800px;
    }

    100% {
        opacity: 0;
        max-height: 0;
    }
}

.animation-openClose {
    max-height: 45px;
    /* Initially hide the content */
    transition: max-height 0.4s ease-in-out;
    overflow: hidden;
}

.animation-closeOpen {
    max-height: 800px;
    /* Set a large enough value to accommodate the content */
    transition: max-height 0.8s ease-in-out;
    overflow: hidden;
}

.animation-DriveCardDropDownOpen {
    animation: driveCardDropDownOpen .3s 1 forwards linear;
}

@keyframes driveCardDropDownOpen {
    0% {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
    }

    60% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        max-height: fit-content;
        overflow: hidden;
    }
}

.animation-DriveCardDropDownClose {
    animation: driveCardDropDownClose .3s 1 forwards linear;
}

@keyframes driveCardDropDownClose {
    0% {
        opacity: 1;
        max-height: fit-content;
        overflow: hidden;
    }

    60% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
    }
}

@media (max-height: 913px) {
    .mail__detailTextArea{
        min-height: 400px;
    }
    .sentMail__detailTextArea{
        min-height: 320px;
    }
}

@media (max-height: 820px) {
    .mail__detailTextArea{
        min-height: 300px;
    }
    .sentMail__detailTextArea{
        min-height: 220px;
    }
}

@media (max-height: 705px) {
    .mail__detailTextArea{
        min-height: 240px;
    }
    .sentMail__detailTextArea{
        min-height: 160px;
    }
}

@media (max-height: 652px) {
    .mail__detailTextArea{
        min-height: 160px;
    }
    .sentMail__detailTextArea{
        min-height: 100px;
    }
}